.AccessRoleWarning{
  padding:20px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: expanded;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
}