.PageContainer {
  margin: 0;
  padding: 0;
  height: 100;
  width: 100%;
  background-color:#f6f6f6;
  height: -webkit-fill-available;
  overflow: auto;
  transition: width 0.3s;
  border-right: 1px solid #eaeaea;
  overflow-anchor: none;
}

/* Live Stats - Start */

.LiveStats{
  background-color: #f6f6f6;
}

.LiveStatsHeader{
  display: flex; 
  justify-content: space-between;
  padding:16px 16px 16px 16px;
  background: #FFF;
  height: 72px;
  border: solid 1px #d2d3d4;
  border-radius: 0px;
}

.LiveStatsName{
  color: #000;
  font-feature-settings: 'ss01' on, 'cv01' on;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-top: 3px;
}

.LiveStatsDescription{
  color: #818181;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.LiveStatsRefreshComponent{
  margin-top: -3px;
}

.LiveStatsRefreshOne{
  color: #34C759;
  text-align: right;
  cursor: pointer;
  font-feature-settings: 'ss01' on, 'cv01' on;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.LiveStatsRefreshTwo{
  color: rgba(0, 0, 0, 0.40);
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'ss01' on, 'cv01' on;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.AgentStatusDataCardsContainer {
  display: flex; 
  gap: 32px; 
  padding: 16px;
}

.LiveStatsContainer{
  background-color: white;
  margin: 16px;
}

.LiveStatsHeaderName{
  display: flex; 
  justify-content: space-between;
  padding:16px 16px 0px 16px;
  color: #000;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 28px;
}

.LiveStatsTabFilter{
  display: flex;
  gap: 10px;
}

.LiveStatsTabFilterInActive{
  color: black;
  text-align: center;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
  cursor: pointer;
}

.LiveStatsTabFilterActive{
  color: #196CCA;
  text-align: center;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; 
  cursor: pointer;
}

.MetricCard{
  border: solid 1px #d2d3d4;
  border-radius: 4px;
  padding:12px;
  background-color: white;
  flex:1;
}

.MetricCardHeader{
  display: flex;
  justify-content: space-between;
  
}

.MetricCard h3{
  margin:0;
  color: #7B7B7B;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.MetricCard p{
  margin:0;
  color: var(--Black, #000);
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.AgentStatusTable{
  padding:16px;
}

.AgentStatusTableBody{
}

.AgentStatusTableHeader {
  color: rgba(0, 0, 0, 0.60) !important;
  font-feature-settings: 'cv01' on !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: bolder !important;
  line-height: 16px !important;
  border-style: none !important;
}


.AgentStatusTableContent{
  color: var(--black-100, #000) !important;
  font-feature-settings: 'cv01' on !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  border-style: none !important;
}

.AgentStatusIcon{
  display: flex;
  align-items: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

/* Live Stats - End */

.LiveFilterSearchButton{
  padding:0px 10px 0px 10px;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  width: 104px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px #d1d3d4;
  background: var(--Alohaa-Red, #F8153B);
}

.LiveFilterHeader{
  color: #7B7B7B;
  text-align: right;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1.2px;
}

.LiveDateFilter{
  border-radius: 4px;
  border: 1px solid var(--Light-Gray, #D9D9D9);
  background: #FFF; 
  width: 136px;
  height: 32px;
  color: #7B7B7B;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding: 0px 15px 0px 15px;
}

.LiveMetricsFilter{
  display: flex;
  gap:5px;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
}
