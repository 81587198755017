/* Top Performers Component - start */

.TopPerformersContainer{
  width:100%;
  border-radius: 8px;
  border: 1px solid #DDD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
  padding: 15px;
}

.TopPerformersHeader{
  display: flex;
  justify-content: space-between;
  padding: 5px 20px 0px 0px;
}

.TopPerformersHeading{
  color: #000;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.TopPerformersHeaderImage{
  margin-top: 5px;
  color: #000;
  text-align: right;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
}

.TopPerformersBody{
  padding: 15px;
}

.TopPerformersTableHeader{
  border: none !important;
  color: rgba(0, 0, 0, 0.60);
  text-align: right;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
}

.TopPerformersTableContent{
  border: none !important;
  color: var(--black-100, #000);
  text-align: right;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

/* Top Performers Component - end */

/* Sentiment Score Component - start */

.SentimentOverviewEntirePage{
  width: calc(100vw - 96px);
  background-color: #f6f6f6;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.SentimentOverviewRow1{
  border-radius: 8px;
  border: 1px solid #DDD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}

.SentimentOverviewRow2{
  display: flex;
  gap:15px;
}

.SentimentOverviewRow3{
  border-radius: 8px;
  border: 1px solid #DDD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}

.SentimentScoreContainer{
  width: 100%;
}

.SentimentScoreContainerHeader{
  display: flex;
  align-items: center;
  padding: 10px 10px 0px 15px;
}

.SentimentOverviewTitle{
  color: #000;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 100% */
}

.SentimentScoreContainerHeading{
  color: #000;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.SentimentScoreContainerFilterSection{

}

.SentimentScoreContainerBody{
  display: flex;
  margin-top: -20px;
}

.SentimentScoreContainerBodyLeft{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:10px;
  height:320px;
  margin: 20px 7.5px 15px 15px;
}

.SentimentScoreCard{
  height: 100px;
  border-radius: 8px;
  border: 1px solid rgba(221, 221, 221, 0.87);
  background: #FFF;
  padding: 10px 20px 10px 10px;
  display: flex;
  justify-content: space-between;
}

.SentimentScoreCardLeft{

}

.SentimentScoreCardTitle{
  color: var(--Alohaa-Blue, #196CCA);
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
}

.SentimentScoreCardValue{
  color: var(--Black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
}

.SentimentScoreCardRight{
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 37.333% */
}

.SentimentScoreCardDescription{
  color: var(--Dark-Gray, #474747);
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 233.333% */
}

.SentimentCallsSection{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
}

.SentimentCallCard{
  height: 100px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(221, 221, 221, 0.87);
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:10px 10px 10px 10px;
}

.SentimentCallCardTop{

}

.SentimentalCallCardHeading{
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
}

.GreenColor{
  color:#07AA3F;
}

.RedColor{
  color:#F8153B;
}

.NeutralColor{
  color:#474747;
}
.SentimentalCallCardData{
  display: flex;
}

.SentimentalCallCardDataValue{
  color: var(--Black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 127.273% */
}

.SentimentalCallCardDataPercentage{
  color: var(--Gray, #9B9B9B);
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.SentimentCallCardBottom{
  display: flex;
  justify-content: flex-end;
  color: var(--Alohaa-Blue, #196CCA);
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 200% */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
}

.SentimentScoreContainerBodyRight{
  width: 100%;
  display: flex;
  justify-content: center;
  height: 320px;
  margin: 20px 15px 15px 7.5px;
  border-radius: 8px;
  border: 1px solid rgba(221, 221, 221, 0.87);
  background: #FFF;
}

.SentimentPieChart{
  width: 370px;
  height: 370px;
}

/* Sentiment Score Component - end */

/* Cloud Component - start */

/* myComponent.css */

.CloudComponent {
  width:100%;
}

.CloudContainerHeader{
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0px 15px;
}

.CloudContainerHeading{
  color: #000;
  font-feature-settings: 'cv01' on;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.CloudContainerFilterSection{

}

.BubbleComponent{
  padding: 20px;
}

.myBubbleUI {
	width: 100%;
  height: 300px;
	border-radius: 50px;
}

.Bubble{
  display: flex;
  justify-content: center;
  align-items: center;
	width: 100%;
	height: 100%;
  font-size: 14px;
	border-radius: 50%;
}

.GreenBubble {
  background-color: lightgreen;
}

.BlueBubble {
  background-color: #80CAFF;
  cursor: pointer;
}

.RedBubble {
  background-color: lightsalmon;
}

.Tooltip-Cloud {
  background-color: rgba(240, 235, 235, 0.85) !important; /* Dark semi-transparent background */
  color: black !important; /* White text */
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.Tooltip-Cloud .tooltip-inner {
  background-color: rgba(240, 235, 235, 0.85) !important;
  color: black !important;
  font-weight: 500;
}

.Tooltip-Cloud .tooltip-arrow::before {
  border-color: rgba(240, 235, 235, 0.85) !important;
}

/* Cloud Component - end */

/* Reports Page - start */

.ReportsPage{
  background: #F6F6F6;
  width: calc(100vw - 100px);
  height: 100%;
}

.ReportsPageHeader{
  height: 64px;
  background: white;
  display: flex;
  align-items: center;
  gap:20px;
  padding:0px 0px 0px 20px;
}

.ReportsPageBody{
  margin:25px;
  display: flex;
  flex-direction: column;
  gap:10px;
}

.ReportCard{
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0px 10px 0px 10px;
  border-radius: 6px;
  background: #FFF;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
}

.ReportCardLeft{
  display: flex;
  align-items: center;
  gap:10px;
}

.ReportIcon{
  height: 26px;
  width: 26px;
}

.ReportMainData{
  display: flex;
  flex-direction: column;
  gap:5px;
}

.ReportCardRight{
  display: flex;
  flex-direction: column;
  gap:5px;
}

.ReportName{
  color: var(--Dark-Gray, #474747);
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ReportDescription{
  color: #5D5D5E;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.ReportType{
  color: var(--Black, #000);
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ViewReportButton{
  color: #196CCA;
  text-decoration: underline;
  font-size: 16px;
  font-family:  Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 5px;
  cursor: pointer;
}

.ReportTime{
  color: #818181;
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ReportsHeading{
  color: #282828;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.CreateReportButton{
  width: 143px;
  height: 32px;
  border-radius: 4px;
  background: #07AA3F;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.221px;
  cursor: pointer;
}

/* Reports Page - end */

/* Create Reports Popup - start */

.CreateReportHead{
  border-radius: 4px 4px 0px 0px;
  background: var(--Alohaa-Red, #ED3833);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
}

.CreateReportBody{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
  margin: 10px 10px 50px 10px;
}

.CreateReportCallType{
  margin: 10px 0px 0px 16px;
}

.CreateReportCallsNumericalType{
  margin: -20px 0px 0px 16px;
}

.CreateReportCallsCount{
  margin: 0px 0px 0px 16px;
}

.CreateReportCallsLowerLimit{
  margin: 0px 0px 0px 16px;
}

.CreateReportDates{

}


.CreateReportSaveButton{
  width: 368px;
  height: 56px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  cursor: pointer;
}

.CreateReportCancelButton{
  color: #ED3833;
  cursor: pointer;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.CreateReportConfirmButton{
  width: 368px;
  height: 56px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ED3833;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  cursor: pointer;
}

.CreateReportEditButton{
  color: #196cca;
  cursor: pointer;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.CreateReportPopup .modal-dialog {
  max-width: 500px;
  width: 100%;
}

.CreateReportPopup .modal-content {
  position: relative;
  overflow: visible;
}

.ApproveCreditDeductionBody{
  font-weight: 500;
  font-size: 16px;
  padding: 13px;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.InsufficientDataNote{
  font-weight: 500;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 10px;
  color: #ED3833;
}

.PopupCloseIcon{
  position: absolute;
  right:-15px;
  top: -15px;
  width: 32px;
  height: 32px;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color:red;
  font-weight: 800;
  border: 1.6px solid red;
  cursor: pointer;
}


/* Create Reports Popup - end */