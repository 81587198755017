.callWidgetText {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 69px;
  left: 24px;
}

.exitCall {
  width: 72px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 22px;
  background: var(--Alohaa-Red, #f8153b);
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exitCallDisabled {
  width: 72px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 22px;
  background: grey;
  position: absolute;
  right: 24px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.callTypeListenDesc {
  color: #f8153b;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.callTypeBargeDesc {
  color: #07aa3f;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}
.callPraticipentName {
  color: var(--Black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: 16px;
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.callPraticipentDesc {
  color: #5c5c5c;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 16px;
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.callPraticipentDuration {
  color: #9b9b9b;
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  margin: 0px;
  top: 10px;
  right: 16px;
  padding: 0px;
}

.callPraticipentStatus {
  text-align: right;
  color: #07aa3f;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  right: 16px;
  top: 27px;
  position: absolute;
  padding: 0px;
}

.bargeFooter {
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: space-around;
  align-items: center;
}

.tooltip.show {
  opacity: 1 !important;
  display: block !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 1;
  white-space: nowrap;
}

.arrow-right {
  position: absolute;
  top: 50%;
  left: 100%;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
  transform: translateY(-50%);
}

.arrow-left {
  position: absolute;
  top: 50%;
  right: 100%;
  border-width: 6px;
  border-style: solid;
  border-color: transparent #333 transparent transparent;
  transform: translateY(-50%);
}

.arrow-top{
  position: absolute;
  bottom: 55%;
  left:42%;
  border-width: 13px;
  border-style: solid;
  border-color: transparent transparent transparent #306cca;
  transform: translateX(-50%);
  transform-origin: 0 0;
  transform: rotate(270deg)
}

.tooltip-text.left {
  right: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.tooltip-text.bottom {
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-text.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}

.tooltip-text.top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}
