.icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip {
    position: absolute;
    bottom: -25px; /* Adjusts position under the icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: #1f2937;
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
  }
  
  .icon-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }